#projects .projects-content {
  width: 90%;
  display: inline-block;
}

#kplc .kplc-content {
  width: 90%;
  display: inline-block;
}

#kplc .kplc-content-intro {
  width: 60%;
  display: inline-block;
  margin-top: -100px;
}

#kplc .kplc-create {
  width: 30%;
  margin-bottom: 50px;
}

#engagements .engagements-content {
  width: 90%;
  display: inline-block;
}

#engagements .engagements-content-intro {
  width: 60%;
  display: inline-block;
  margin-top: -100px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #kplc .kplc-content-intro,
  #engagements .engagements-content-intro {
    width: 90%;
  }
}
