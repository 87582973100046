#legal {
  margin: 80px 0px;
  display: inline-block;
  width: 50%;
}

#legal .legal-content {
  margin-top: 80px;
  text-align: left;
}

#legal .legal-content ul {
  margin: 40px 0px;
}

#legal .legal-content ul li {
  color: black;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #legal {
    width: 90%;
  }
}
