#immogolf-title-container {
  background-image: url(../../images/golf.png);
  height: 100vh;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#immogolf #immogolf-title {
  height: 100vh;
  width: 800px;
}

#immogolf #immogolf-title .immogolf-title1 {
  color: #4c4c4c;
  font-size: 40px;
  font-weight: 500;
  padding-top: 150px;
  margin-bottom: 28px;
}

#immogolf #immogolf-title .immogolf-title2 {
  color: #4c4c4c;
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 22px;
}

#immogolf #immogolf-title input {
  width: 300px;
}

#immogolf #immogolf-title .immogolf-title3-container {
  margin-top: 30px;
}

#immogolf #immogolf-title .immogolf-title3 {
  color: #f27249;
  font-size: 40px;
  font-weight: 400;
}

#immogolf .immogolf-content {
  width: 90%;
  display: inline-block;
}

#immogolf .immogolf-content-intro {
  width: 50%;
  display: inline-block;
}

#immogolf .immogolf-content-reg-info {
  width: 50%;
  display: inline-block;
}

#immogolf #registrations-button {
  margin-top: 40px;
  width: 200px;
  height: 34px;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #f27249;
  color: white;
  padding: 5px 5px;
  border-radius: 4px;
}
#immogolf #registrations-button p {
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin: 0px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #immogolf .immogolf-content-intro {
    width: 90%;
    display: inline-block;
  }

  #immogolf .immogolf-content-reg-info {
    width: 90%;
    display: inline-block;
  }

  #immogolf #immogolf-title {
    height: 100vh;
    width: 55vw;
  }
  #immogolf #immogolf-title .immogolf-title1 {
    font-size: 20px;
    font-weight: 500;
    padding-top: 300px;
  }

  #immogolf #immogolf-title .immogolf-title2 {
    font-size: 30px;
    font-weight: 700;
  }

  #immogolf #immogolf-title .immogolf-title3 {
    font-size: 26px;
    font-weight: 400;
  }
}

/* mobile */
@media only screen and (max-width: 400px) {
  #immogolf #immogolf-title {
    height: 100vh;
    width: 100vw;
  }
  #immogolf #immogolf-title .immogolf-title1 {
    font-size: 32px;
    font-weight: 600;
    padding-top: 250px;
    color: black;
  }

  #immogolf #immogolf-title .immogolf-title2 {
    font-size: 38px;
    font-weight: 700;
    color: black;
  }

  #immogolf #immogolf-title .immogolf-title3 {
    font-size: 26px;
    font-weight: 600;
  }
}
