.primary-button button {
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: #f27249;
  color: white;
  padding: 10px 20px;
  box-shadow: 2px 4px 7px 2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.secundary-button button {
  width: 100px;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  background-color: #f27249;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
}

.link-button {
  cursor: pointer;
  background-color: #f27249;
  border-radius: 4px;
  height: 32px;
  width: 100px;
  float: right;
  display: block;
  text-align: center;
  box-shadow: 2px 4px 7px 2px rgba(0, 0, 0, 0.4);
}

.link-button a,
.link-button-center a {
  text-decoration: none;
}

.link-button p,
.link-button-center p {
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin-top: 8px;
}

.link-button-center {
  cursor: pointer;
  background-color: #f27249;
  border-radius: 4px;
  height: 32px;
  width: 200px;
  display: block;
  text-align: center;
  margin: 10px auto;
  padding-top: 2px;
}

.pdf-button {
  cursor: pointer;
  background-color: #f27249;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  height: 32px;
  width: 150px;
}

.pdf-button a {
  text-decoration: none;
}

.pdf-button p {
  font-size: 12px;
  font-weight: bold;
  color: white;
  margin: 0px;
  margin-top: 8px;
}

.icon-button button {
  width: 34px;
  height: 34px;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #f27249;
  color: white;
  padding: 5px 5px;
  border-radius: 4px;
}

.donation-button {
  cursor: pointer;
  background-color: #f27249;
  border-radius: 30px 0px 0px 30px;
  width: 12vw;
  display: block;
  text-align: center;
  box-shadow: 2px 4px 7px 2px rgba(0, 0, 0, 0.4);
  margin-top: 85vh;
  margin-left: 88vw;
  position: fixed;
}

.donation-button a {
  text-decoration: none;
}

.donation-button p {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.plain-button button {
  border: none;
  background-color: transparent;
  display: inline-block;
  margin-top: 5px;
}

.plain-button button p {
  color: #555555;
  cursor: pointer;
  font-size: 12px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  .donation-button {
    width: 25vw;
    margin-left: 75vw;
  }
}

/* mobile */
@media only screen and (max-width: 400px) {
  .donation-button {
    width: 40vw;
    margin-left: 60vw;
  }
  .donation-button p {
    font-size: 12px;
  }
}
