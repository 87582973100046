#footer {
  text-align: left;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  color: #555555;
}

#footer #footer-content {
  margin: 40px 0px;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}

#footer #footer-content-small {
  margin: 40px 0px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}

#footer a {
  text-decoration: none;
  color: #555555;
}

#footer #social-links {
  margin: auto 0px auto auto;
}

#footer .social-link {
  margin-right: 10px;
}

/* iPad */
@media only screen and (max-width: 400px) {
  #footer #footer-content {
    margin: 20px 0px;
    width: 75%;
    display: inline-block;
  }
  #footer .footer-item {
    margin-top: 50px;
  }
  #footer #social-links {
    margin: 30px 0px auto auto;
  }
}
