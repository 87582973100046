#login {
  margin-top: 150px;
  width: 30%;
  display: inline-block;
}

#login-button {
  margin-top: 20px;
}

#login #form {
  background-color: white;
  padding: 20px;
  color: #555555;
}

#login #form h2 {
  font-size: 34px;
}

#login #form hr {
  border: 0;
  border-bottom: solid 1px;
  border-bottom-color: rgba(144, 144, 144, 0.25);
  margin: 20px 0px;
}

#login #form label {
  display: block;
  text-align: left;
  font-size: 12px;
  margin: 20px 0px 10px 0px;
}

#login #form input {
  display: block;
  height: 30px;
  margin: 0px;
  width: 95%;
  padding: 0px 10px;
  background: rgba(144, 144, 144, 0.075);
  border: solid rgba(144, 144, 144, 0.25) 1px;
  border-radius: 4px;
}
