#home {
  background-image: url(../../images/kidzPic.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
}

#home #home-title {
  height: 100vh;
}

#home #home-title img {
  margin-top: 320px;
  width: 400px;
}

#home #home-title h1 {
  color: white;
  font-size: 48px;
}

#home .home-content {
  width: 90%;
  display: inline-block;
}

#home .home-who-edit-button {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

#home #home-who {
  margin: 150px 0px;
}

#home #home-who-content {
  display: flex;
  justify-content: space-between;
}

#home #home-who .home-who-article {
  display: inline-block;
  width: 31%;
}

#home #home-title h1 {
  font-size: 52px;
}

/* iPad */
@media only screen and (max-width: 400px) {
  #home #home-title img {
    margin-top: 250px;
    width: 200px;
  }
  #home #home-title h1 {
    color: white;
    font-size: 20px;
  }
}
