#donators {
  background-color: white;
  padding: 20px;
  margin: 50px 0px;
}

#donators .donators-edit-button button {
  width: 300px;
}

#donators .donators-edit-button {
  margin-top: 40px;
}

#donators-view h1,
#donators-edit h1 {
  font-size: 40px;
}

#donators-view p,
#donators-edit p {
  color: #a6a6a6;
  text-align: left;
  font-size: 16px;
  margin: 10px;
}

#donators-view .donators-grid,
#donators-edit .donators-grid {
  display: grid;
  grid-template-columns: 20% 80%;
}

#donators-view .event-donators-grid,
#donators-edit .event-donators-grid {
  display: grid;
  grid-template-columns: 20% 80%;
}

#donators-view .grid-item,
#donators-edit .grid-item {
  display: inline-block;
}

#donators-view .other-donators-item,
#donators-edit .other-donators-item {
  display: inline-block;
  margin: 0px 20px;
}

#donators-view .main-partners .donator-img img,
#donators-edit .main-partners .donator-img img {
  margin: 10px 0px;
  width: 70%;
  max-width: 300px;
}

#donators-view .fundraising-partners .donator-img img,
#donators-edit .fundraising-partners .donator-img img {
  margin: 0px 10px;
  width: 90%;
  max-width: 120px;
}

#donators-view .fundraising-partners .donator-img,
#donators-edit .fundraising-partners .donator-img {
  margin: 5px 10px;
  display: inline-block;
  width: 18%;
}

#donators-view .event-partners .donator-img img,
#donators-edit .event-partners .donator-img img {
  margin: 0px 10px;
  width: 90%;
  max-width: 200px;
}

#donators-view .event-partners .donator-img,
#donators-edit .event-partners .donator-img {
  margin: 20px 10px 30px 10px;
  display: inline-block;
  width: 100%;
}

#donators-view .event-supporter,
#donators-edit .event-supporter {
  margin: 5px 10px;
  display: inline-block;
  width: 18%;
}

#donators-view .event-supporter .donator-img img,
#donators-edit .event-supporter .donator-img img {
  margin: 0px 10px;
  width: 90%;
  max-width: 120px;
}

#donators-edit .delete-button {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  vertical-align: top;
}

#donators-edit .more-donators-item {
  display: inline-block;
}

#donators-edit input {
  display: inline-block;
  width: 100px;
}

#donators-edit .save-button {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}
#donators-edit p {
  text-align: center;
}

#donators-edit .donator-logo-item {
  height: 100px;
}

#donators-edit .upload-name {
  display: inline-block;
  margin-left: 5px;
}

#donators-edit .upload-name p {
  font-size: 12px;
  color: #000000;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #donators-view .donators-grid,
  #donators-edit .donators-grid {
    display: inline-block;
  }

  #donators-view .event-donators-grid {
    display: inline-block;
  }
  #donators-view .grid-item,
  #donators-edit .grid-item {
    margin: 30px 0px;
  }

  #donators-view .event-partners,
  #donators-edit .event-partners {
    display: block;
  }
}

/* iPad */
@media only screen and (max-width: 800px) {
  #donators-view .other-donators-item,
  #donators-edit .other-donators-item {
    width: 100%;
  }
}
