#app {
  text-align: center;
}

body {
  background-color: #f2f2f2;
}

body,
html {
  height: 100%;
  margin: 0px;
}

h1 {
  font-family: "Hiragino Mincho ProN", serif;
  font-weight: 500;
  color: #555555;
}

h2 {
  font-family: "Hiragino Mincho ProN", serif;
  font-weight: 500;
  color: #555555;
}

h3 {
  font-family: "Hiragino Mincho ProN", serif;
  font-weight: 500;
}

li {
  color: #555555;
  font-size: 16px;
}

p {
  font-size: 18px;
}

hr {
  color: #d4d4d4;
  border: 0;
  border-bottom: solid 1px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-60 {
  margin-top: 40px;
}

.height-10 {
  height: 10px;
}

.height-20 {
  height: 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
