#immoball-title-container {
  background-image: url(../../images/ball.jpg);
  height: 100vh;
  width: 100%;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#immoball #immoball-title {
  height: 100vh;
}

#immoball #immoball-title .immoball-title1 h1 {
  color: white;
  font-size: 48px;
  padding-top: 80px;
  margin: 0px;
  /*font-family: "Snell Roundhand", cursive;*/
}

#immoball #immoball-title .immoball-title2 h1 {
  color: white;
  font-size: 52px;
  margin: 0px;
  font-weight: bold;
  /*font-family: "Snell Roundhand", cursive;*/
}

#immoball #immoball-title .immoball-title3 p {
  color: #f27249;
  font-size: 50px;
  font-weight: 600;
  margin-top: 65vh;
}

#immoball #immoball-title .immoball-title1 {
  margin: 0px;
}

#immoball #immoball-title .immoball-title2 {
  margin: 0px;
}

#immoball #immoball-title input {
  width: 300px;
}

#immoball #immoball-title .immoball-title3-container {
  margin-top: 30px;
}

#immoball #immoball-title .immoball-title3 {
  color: #f27249;
  font-size: 40px;
  font-weight: 400;
}

#immoball .immoball-content {
  width: 90%;
  display: inline-block;
}

#immoball .immoball-content-intro {
  width: 50%;
  display: inline-block;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #immoball #immoball-title .immoball-title1 h1 {
    font-size: 30px;
    font-weight: bold;
  }

  #immoball #immoball-title .immoball-title2 h1 {
    font-size: 40px;
    margin: 0px;
    font-weight: bold;
  }

  #immoball #immoball-title .immoball-title3 p {
    color: white;
    font-size: 20px;
    font-weight: 600;
    margin-top: 10px;
  }
  #immoball .immoball-content-intro {
    width: 90%;
  }
}

/* mobile */
@media only screen and (max-width: 400px) {
  #immoball #immoball-title .immoball-title1 h1 {
    font-size: 16px;
    font-weight: bold;
    padding-top: 140px;
  }

  #immoball #immoball-title .immoball-title2 h1 {
    font-size: 24px;
    margin: 0px;
    font-weight: bold;
  }

  #immoball #immoball-title .immoball-title3 p {
    color: white;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0px;
  }
  #immoball .immoball-content-intro {
    width: 90%;
  }
}


#whatsup {
  margin-top: 80px;
  display: inline-block;
  width: 90%;
}

#whatsup .whatsup-create {
  margin: 50px 0px 100px 0px;
  display: grid;
  grid-template-columns: [col1-start] 1fr [col2-start] 1fr [col2-end];
  grid-template-rows: [row1-start] 1fr [row2-start] 1fr [row2-end];
  grid-gap: 40px;
}

#whatsup .whatsup-create-article {
  grid-row: row1-start / row2-end;
  grid-column: col1-start;
}

#whatsup .whatsup-create-twitter {
  grid-row: row1-start;
  grid-column: col2-start;
  margin-top: 40px;
}

#whatsup .whatsup-create-facebook {
  grid-row: row2-start;
  grid-column: col2-start;
  margin-top: 40px;
}
