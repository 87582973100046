#contact .contact-form {
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  width: 50vw;
  margin-top: 150px;
}

#contact .contact-content-form {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

#contact .contact-content-address {
  width: 30vw;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10vh;
}

#contact .address-content {
  margin-top: 12vh;
}

#contact p {
  color: #555555;
}

#contact hr {
  color: black;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #contact .contact-form {
    width: 90%;
  }
  #contact .contact-content-form {
    width: 90%;
    margin-top: 60px;
  }
  #contact .contact-content-address {
    width: 90%;
  }
  #contact .address-content {
    margin-top: 40px;
  }
  #contact .contact-content-address {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 30px;
  }
}
