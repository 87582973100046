#about .about-content {
  display: inline-block;
  vertical-align: top;
  width: 90%;
}

#about .about-content-founders {
  display: inline-block;
  vertical-align: top;
  width: 40%;
  margin: 0px 20px;
}

#about .about-adresses {
  background-color: white;
  margin: 50px 0px;
}

#about .about-revision {
  padding: 20px;
}

#about .about-revision a {
  text-decoration: none;
}

#about .about-content h1 {
  color: #555555;
  text-transform: uppercase;
  font-size: 18px;
}

#about .about-content-founders h1 {
  color: #555555;
  text-transform: uppercase;
  font-size: 18px;
}

#about p {
  color: #a6a6a6;
  padding-top: 5px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  #about .about-content-founders {
    width: 90%;
  }
}
