#home-video-section {
  margin: 100px 0px;
}

#more-videos-button {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

#videos {
  margin-top: 80px;
  display: inline-block;
  width: 90%;
}

#videos .videos-create {
  margin: 10px 0px 20px 0px;
  width: 450px;
  display: inline-block;
}
