#presse {
  margin-top: 80px;
  display: inline-block;
  width: 90%;
}

#presse .presse-create {
  margin: 50px 0px 100px 0px;
  display: grid;
  grid-template-columns: [col1-start] 1fr [col2-start] 1fr [col2-end];
  grid-template-rows: [row1-start] 1fr [row2-start] 1fr [row2-end];
  grid-gap: 40px;
}

#presse .presse-create-article {
  grid-row: row1-start / row2-end;
  grid-column: col1-start;
}

#presse .presse-create-twitter {
  grid-row: row1-start;
  grid-column: col2-start;
  margin-top: 40px;
}

#presse .presse-create-facebook {
  grid-row: row2-start;
  grid-column: col2-start;
  margin-top: 40px;
}
