.gallery {
  margin: 120px auto;
  padding: 20px;
  background-color: #fff;
}

.gallery .gallery-show {
  height: 600px;
  overflow: scroll;
}

.tab {
  overflow: hidden;
  background-color: #f1f1f1;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab .tab-link:hover {
  background-color: #fff;
}

.tab .tab-link-active {
  background-color: #fff;
}

.tab-content {
  padding: 6px 0px;
}

/* iPad */
@media only screen and (min-width: 800px) {
  .react-images__view-image {
    height: 95vh !important;
  }
}


