.main-video-player {
  display: inline-block;
}

.main-video-player label {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0px 10px 0px;
}

.main-video-player input {
  display: block;
  margin: 0px auto 10px auto;
  padding: 0px 10px;
  border: solid rgba(144, 144, 144, 0.25) 1px;
  border-radius: 4px;
  height: 30px;
  width: 400px;
}

#video-grid {
  margin: 50px 0px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.video-grid-item {
  width: 100%;
}

#video-grid .video-edit-button {
  margin-top: 20px;
}

#video-grid .video-edit-button div {
  display: inline-block;
  margin: 0px 5px;
}

.video {
  background-color: white;
  padding: 20px;
}

.video h1 {
  color: #555555;
  text-transform: uppercase;
  font-size: 24px;
}

.video h2 {
  color: #a6a6a6;
  font-size: 18px;
}

.video .video-edit,
.video-create {
  margin-top: 50px;
  width: 100%;
}

.video-view {
  margin-top: 20px;
}

.video-view h3 {
  font-weight: normal;
}

.video label {
  display: block;
  text-align: left;
  font-size: 12px;
  margin: 20px 0px 10px 0px;
}

.video input[type="text"] {
  display: block;
  margin: 0px;
  width: 95%;
  padding: 0px 10px;
  border: solid rgba(144, 144, 144, 0.25) 1px;
  border-radius: 4px;
  height: 30px;
}

.video .save-button {
  margin-top: 20px;
}

.video .form-info p {
  color: red;
  font-size: 12px;
  text-align: center;
}

/* iPad */
@media only screen and (max-width: 1500px) {
  #video-grid {
    margin: 50px 0px;
    display: inline-block;
  }
}
