#article-grid {
  margin: 50px 0px;
  display: grid;
}

.article-grid-2 {
  grid-gap: 40px;
  grid-template-columns: repeat(2, 1fr);
}

.article-grid-3 {
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);
}

.article-grid-4 {
  grid-gap: 40px;
  grid-template-columns: repeat(4, 1fr);
}

.article-grid-6 {
  grid-gap: 40px;
  grid-template-columns: repeat(6, 1fr);
}

#article-grid .article-edit-button {
  margin-top: 20px;
}

#article-grid .article-edit-button div {
  display: inline-block;
  margin: 0px 5px;
}

#article-grid .article-url {
  margin: 10px 0px;
}

.article {
  background-color: white;
  padding: 20px;
}

.article h1 {
  color: #555555;
  text-transform: uppercase;
  font-size: 18px;
}

.article h2 {
  color: #a6a6a6;
  font-size: 14px;
}

.article hr {
  border: 0;
  border-bottom: solid 1px;
  border-bottom-color: rgba(144, 144, 144, 0.25);
  margin: 20px 0px;
}

.article img {
  max-height: 300px;
  max-width: 100%;
}

.article .article-edit,
.article-create {
  margin-top: 50px;
}

.article label {
  display: block;
  text-align: left;
  font-size: 12px;
  margin: 20px 0px 10px 0px;
}

.article textarea {
  height: 100px;
}

.article .article-file-upload {
  margin-bottom: 50px;
}

.article .article-file-upload .uploaded {
  margin: 5px 0px;
}

.article .article-file-upload input {
  margin-bottom: 10px;
}

.article .article-file-upload p {
  font-size: 14px;
  margin: 5px 10px 5px 0px;
  display: inline-block;
}

.article .article-file-upload .icon-button {
  display: inline-block;
}

.article .save-button {
  margin-top: 20px;
}

.article .form-info p {
  color: red;
  font-size: 12px;
  text-align: center;
}
.article-link-button {
  margin-top: 10px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  .article-grid-2,
  .article-grid-3,
  .article-grid-4,
  .article-grid-6 {
    grid-gap: 40px;
    grid-template-columns: repeat(1, 1fr);
  }
}
