.title,
.title-small {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: 40px;
}

.title {
  padding: 100px 0px 50px 0px;
}

.title-small {
  margin: 20px 0px;
}

.title h1 {
  font-size: 40px;
  margin: 10px;
  color: #555555;
}

.title-small h2 {
  margin: 5px;
  color: #555555;
}

.title hr,
.title-small hr {
  color: #555555;
  border: 0;
  border-bottom: solid 1px;
}

.title .title-button-container {
  margin-top: 10px;
  float: right;
  justify-content: flex-end;
}

/* iPad */
@media only screen and (max-width: 400px) {
  .title h1 {
    font-size: 30px;
  }
}
