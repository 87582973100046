.infoRow-grid {
  margin: 120px 0px;
  display: grid;
}

.infoRow-grid .infoRow-edit-button {
  margin-top: 20px;
}

.infoRow-grid-2 {
  grid-template-columns: auto auto;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 120px;
}

.infoRow-grid-3 {
  grid-template-columns: auto auto auto;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}

.infoRow {
  background-color: white;
  padding: 20px;
}

.infoRow h1 {
  color: #555555;
  text-transform: uppercase;
  font-size: 24px;
}

.infoRow h2 {
  color: #a6a6a6;
  text-transform: uppercase;
  font-size: 16px;
}

.infoRow hr {
  border: 0;
  border-bottom: solid 1px;
  border-bottom-color: rgba(144, 144, 144, 0.25);
  margin: 20px 0px;
}

.infoRow .infoRow-edit {
  margin-top: 50px;
}

.infoRow .infoRow-edit h2 {
  color: #555555;
}

.infoRow label {
  display: block;
  text-align: left;
  font-size: 12px;
  margin: 20px 0px 10px 0px;
}

.infoRow input,
textarea {
  display: block;
  margin: 0px;
  width: 95%;
  padding: 0px 10px;
  border: solid rgba(144, 144, 144, 0.25) 1px;
  border-radius: 4px;
}

.infoRow input {
  height: 30px;
}

.infoRow textarea {
  height: 100px;
}

.infoRow .save-button {
  margin-top: 20px;
}

.infoRow .form-info p {
  color: red;
}

.infoRow .infoRow-link-button {
  margin-top: 20px;
}

/* iPad */
@media only screen and (max-width: 800px) {
  .infoRow-grid-2 {
    grid-template-columns: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 120px;
  }

  .infoRow-grid-3 {
    grid-template-columns: auto;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 40px;
  }
}
